.container {
    max-width: 1100px;
    padding: 0 10px;
}

.containerImage {
    width: 100%;
    max-width: 100%;
}

.containerSummary {
    text-align: left;
}