@import-normalize;

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: sans-serif;
    background-color: var(--tg-theme-bg-color, #ffffff);
    color: var(--tg-theme-text-color, #222222);
    color-scheme: var(--tg-color-scheme);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    text-align: center;
    line-height: 24px;
}

img {
    max-width: 100%;
}

a {
    color: var(--tg-theme-link-color, #2678b6);
}

button {
    display: block;
    width: 100%;
    font-size: 14px;
    margin: 15px 0;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    background-color: var(--tg-theme-button-color, #50a8eb);
    color: var(--tg-theme-button-text-color, #ffffff);
    cursor: pointer;
}

button[disabled] {
    opacity: 0.6;
    cursor: auto;
    pointer-events: none;
}

p {
    white-space: pre-wrap;
}